/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// @mui/icons-material
import Mail from "@mui/icons-material/Mail";
import Favorite from "@mui/icons-material/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";

import styles from "styles/jss/nextjs-material-kit-pro/pages/componentsSections/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFooter() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Footer
        theme="transparent"
        content={
          <div>
            <ul className={classes.socialButtons}>
              <li>
                <Button
                  justIcon
                  simple
                  href="https://twitter.com/wearetribalism"
                  color="twitter"
                  target="_blank"
                >
                  <i className="fab fa-twitter" />
                </Button>
              </li>
              <li>
                <Button
                  justIcon
                  simple
                  href="https://www.linkedin.com/company/tribalism"
                  color="linkedin"
                  target="_blank"
                >
                  <i className="fab fa-linkedin" />
                </Button>
              </li>
              <li>
                <Button
                  justIcon
                  simple
                  href="https://www.instagram.com/wearetribalism/"
                  color="instagram"
                  target="_blank"
                >
                  <i className="fab fa-instagram" />
                </Button>
              </li>
              <li>
                <Button
                  justIcon
                  simple
                  href="https://www.facebook.com/wearetribalism/"
                  color="facebook"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
              </li>
              <li>
                <Button
                  justIcon
                  simple
                  href="https://www.youtube.com/channel/UC8vdgfXvgOEb2c6OtC3InEw"
                  color="youtube"
                  target="_blank"
                >
                  <i className="fab fa-youtube" />
                </Button>
              </li>
            </ul>
            <div className={classNames(classes.pullCenter, classes.copyRight)}>
              Copyright &copy; {1900 + new Date().getYear()} Tribalism All
              Rights Reserved
            </div>
          </div>
        }
      >
        <div className={classes.footer}>
          <GridContainer>
            <GridItem xs={12} sm={2} md={2}>
              <h5>About Us</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/our-work">Our Work</a>
                </li>
                <li>
                  <a href="/about/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <a href="/contact-us">Contact</a>
                </li>
                
              </ul>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
              <h5>Data & Analytics</h5>
              <ul className={classes.linksVertical}>
              
                <li>
                  <a href="/services/analytics-services">Analytics</a>
                </li>
                <li>
                  <a href="/services/ga4-implementation-services">GA4 Implementation</a>
                </li>
                <li>
                  <a href="/services/google-tag-manager-services">
                    Google Tag Manager
                  </a>
                </li>
                <li>
                  <a href="/services/training">Training</a>
                </li>
                <li>
                  <a href="/services/data-studio-services">Looker Studio</a>
                </li>
                <li>
                  <a href="/services/data-visualization-and-reporting-services">
                    Advanced Dashboards & Reporting
                  </a>
                </li>

              </ul>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
              <h5>Marketing</h5>
              <ul className={classes.linksVertical}>
                
                <li>
                  <a href="/services/google-ads-services">
                    SEM (Google Ads/Bing)
                  </a>
                </li>
                <li>
                  <a href="/glossary/web-analytics-glossary">
                    Web Analytics Glossary
                  </a>
                </li>
                <li>
                  <a href="/glossary/web-development-glossary">
                    Web Development Glossary
                  </a>
                </li>
              </ul>
            </GridItem>
            
            <GridItem xs={12} sm={2} md={2}>
              <h5>Programming / Web Development</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <a href="/services/other-services">
                    App Development & Design
                  </a>
                </li>
                <li>
                  <a href="/services/web-development-services">
                    Web Development
                  </a>
                </li>
                <li>
                  <a href="/services/other-services">Script Development</a>
                </li>
                
              </ul>
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
              <h5>Subscribe to Newsletter</h5>
              <p>
                Join our newsletter and get news in your inbox every week! We
                hate spam too, so no worries about this.
              </p>
              <form>
                <CustomInput
                  id="footeremail"
                  formControlProps={{
                    fullWidth: false,
                    className: classes.customFormControl,
                  }}
                  inputProps={{
                    placeholder: "Your Email...",
                  }}
                />
                <Button color="warning" justIcon>
                  <Mail />
                </Button>
              </form>

              <a
                href="https://www.google.com/partners/agency?id=4903353289"
                target="_blank"
              >
                <img
                  width="75"
                  src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg"
                />
              </a>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  );
}
